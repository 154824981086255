import React from "react"
import "prismjs/themes/prism.css"

import { Theme, defaultTheme } from "./src/utils/theme"

class RootWrapper extends React.Component {
  constructor() {
    super()
    this.state = {
      theme: this.getUpdatedTheme(),
    }
  }
  getUpdatedTheme() {
    return {
      ...defaultTheme,
      preferredTheme: window.__preferredTheme || "light",
    }
  }
  componentDidMount() {
    this.setState({
      theme: this.getUpdatedTheme(),
    })
    window.__onThemeChange = () => {
      this.setState({
        theme: this.getUpdatedTheme(),
      })
    }
  }
  render() {
    return (
      <Theme.Provider value={this.state.theme}>
        <>
          {this.props.children}
        </>
      </Theme.Provider>
    )
  }
}

export const wrapRootElement = ({ element }) => (
  <RootWrapper children={element} />
)
