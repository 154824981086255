import React, { useContext } from "react"
import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

import "./global.css"

// https://github.com/gaearon/overreacted.io/blob/9a374db41c27be25938c27aa612b0268f3e7ddb9/src/utils/typography.js
Wordpress2016.overrideThemeStyles = () => ({
  a: {
    color: "var(--textLink)",
  },
  hr: {
    background: "var(--hr)",
  },
  "a.gatsby-resp-image-link": {
    boxShadow: "none",
  },
  // These two are for gatsby-remark-autolink-headers:
  "a.anchor": {
    boxShadow: "none",
  },
  'a.anchor svg[aria-hidden="true"]': {
    stroke: "var(--textLink)",
  },
  "p code": {
    fontSize: "1rem",
  },
  // TODO: why tho
  "h1 code, h2 code, h3 code, h4 code, h5 code, h6 code": {
    fontSize: "inherit",
  },
  "li code": {
    fontSize: "1rem",
  },
  blockquote: {
    color: "inherit",
    borderLeftColor: "inherit",
    opacity: "0.8",
  },
  "blockquote.translation": {
    fontSize: "1em",
  },
  "figcaption": {
    filter: "brightness(60%)",
  }
})

delete Wordpress2016.googleFonts
Wordpress2016.headerFontFamily = ["Trueno Bold", "Helvetica", "sans-serif"]
Wordpress2016.bodyFontFamily = ["Trueno", "Helvetica", "sans-serif"]

const typography = new Typography(Wordpress2016)
const rhythm = typography.rhythm
const scale = typography.scale

export const defaultTheme = {
  scale,
  rhythm,
  preferredTheme: "light",
}

export const Theme = React.createContext(defaultTheme)

export const useTheme = () => {
  return useContext(Theme)
}

export default typography
