import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import * as Wavedrom from "wavedrom"
import waveSkinDark from "wavedrom/skins/dark.js"
import { useTheme } from "../utils/theme"

const Waveform = ({ code }) => {
  const ref = useRef()
  const theme = useTheme()
  const { rhythm, preferredTheme } = theme

  useEffect(() => {
    if (preferredTheme === "dark") {
      Wavedrom.renderWaveElement(1, code, ref.current, waveSkinDark)
    } else {
      Wavedrom.renderWaveElement(1, code, ref.current, Wavedrom.waveSkin)
    }
  }, [code, preferredTheme])
  return (
    <div
      ref={ref}
      className="waveform"
      style={{
        maxWidth: rhythm(24),
        marginBottom: `${rhythm(1.25)}`,
        overflow: "auto",
      }}
    />
  )
}

Waveform.defaultProps = {
  code: {},
}

Waveform.propTypes = {
  code: PropTypes.object,
}

export default Waveform
